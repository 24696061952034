import { PageName } from '~/config'
import { CommunicationManager } from '~/services/communicationManager'

// This activate the controllers that are active on the current page (e.g. the MedicationProfileController on the client profile page)
// The activate page can only be called when i18n is fully initialized
// The first run is done in App.vue when the page is mounted
// Watching the route name change property calls the callback after the child components of a page are loaded
// Therefore, we need middleware just before the page is loaded to run the activatePage method
let isFirstRun = true
export default defineNuxtRouteMiddleware((to) => {
  if (process.env.TEST) {
    return
  }

  if (isFirstRun) {
    isFirstRun = false
    return
  }

  CommunicationManager.activatePage(to.name as PageName)
})
