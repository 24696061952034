import { documentId, limit, Query, query, where } from 'firebase/firestore'
import { AppUser } from '~/models'
import { MapService } from './serviceLive'

export class AppUserService extends MapService<AppUser> {
  BATCH_SIZE = 30

  constructor() {
    super('AppUsers')
  }

  public listenForIds(userIds: string[]): void {
    const queries: Query[] = []

    for (let i = 0; i < userIds.length; i += this.BATCH_SIZE) {
      const batch = userIds.slice(i, i + this.BATCH_SIZE)
      queries.push(query(this.collection, where(documentId(), 'in', batch)))
    }

    this.listenGroup(queries)
  }

  public listenForSearchQuery(searchQuery: string): void {
    this.listen(
      query(
        this.collection,
        where('Email', '>=', searchQuery),
        where('Email', '<=', `${searchQuery}\uF8FF`),
        limit(50)
      )
    )
  }
}
