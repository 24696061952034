import { query, where } from 'firebase/firestore'
import { MedicationAgreement } from '~/models'
import { MapService } from './serviceLive'

export class MedicationAgreementService extends MapService<MedicationAgreement> {
  constructor() {
    super('MedicationAgreement')
  }

  public listenForId(userId: string): void {
    const listenQuery = query(this.collection, where('UserId', '==', userId))
    this.listen(listenQuery)
  }
}
