import { limit, orderBy, query, where } from 'firebase/firestore'
import { ArrayService } from './serviceLive'
import { Parcel } from '~/models'

export class ParcelService extends ArrayService<Parcel> {
  constructor() {
    super('Parcels')
  }

  public listenForLatestParcelOfUser(userId: string): void {
    const listenQuery = query(
      this.collection,
      where('UserId', '==', userId),
      orderBy('Created', 'desc'),
      limit(1)
    )

    this.listen(listenQuery)
  }

  public listenForId(userId: string): void {
    const listenQuery = query(
      this.collection,
      where('UserId', '==', userId),
      orderBy('DeliveryDate', 'desc')
    )
    this.listen(listenQuery)
  }
}
