
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accountswPH1IH9kvKMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts.vue?macro=true";
import { default as apothekers_45controleteVbjBg7bPMeta } from "/opt/atlassian/pipelines/agent/build/pages/apothekers-controle.vue?macro=true";
import { default as bestanden_45inladenqR1fHzCAyyMeta } from "/opt/atlassian/pipelines/agent/build/pages/bestanden-inladen.vue?macro=true";
import { default as bestellingencRrtR4kvnTMeta } from "/opt/atlassian/pipelines/agent/build/pages/bestellingen.vue?macro=true";
import { default as controle_45levering4cVZ2Vk0JUMeta } from "/opt/atlassian/pipelines/agent/build/pages/controle-levering.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as inloggen7sAPD1tvjiMeta } from "/opt/atlassian/pipelines/agent/build/pages/inloggen.vue?macro=true";
import { default as klantprofieltOgbNAJATtMeta } from "/opt/atlassian/pipelines/agent/build/pages/klantprofiel.vue?macro=true";
import { default as leveringen_45overzichtosZUgmsfQDMeta } from "/opt/atlassian/pipelines/agent/build/pages/leveringen-overzicht.vue?macro=true";
import { default as nazendingen4DxNFcTK6EMeta } from "/opt/atlassian/pipelines/agent/build/pages/nazendingen.vue?macro=true";
import { default as parallel_45bestellenLQH5qJA9OVMeta } from "/opt/atlassian/pipelines/agent/build/pages/parallel-bestellen.vue?macro=true";
import { default as printenws5zocMxc7Meta } from "/opt/atlassian/pipelines/agent/build/pages/printen.vue?macro=true";
import { default as receptverwerkingBCVA8a1SbeMeta } from "/opt/atlassian/pipelines/agent/build/pages/receptverwerking.vue?macro=true";
export default [
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts.vue")
  },
  {
    name: "apothekers-controle",
    path: "/apothekers-controle",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/apothekers-controle.vue")
  },
  {
    name: "bestanden-inladen",
    path: "/bestanden-inladen",
    meta: bestanden_45inladenqR1fHzCAyyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/bestanden-inladen.vue")
  },
  {
    name: "bestellingen",
    path: "/bestellingen",
    meta: bestellingencRrtR4kvnTMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/bestellingen.vue")
  },
  {
    name: "controle-levering",
    path: "/controle-levering",
    meta: controle_45levering4cVZ2Vk0JUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/controle-levering.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexOD9t3F2bSJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "inloggen",
    path: "/inloggen",
    meta: inloggen7sAPD1tvjiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/inloggen.vue")
  },
  {
    name: "klantprofiel",
    path: "/klantprofiel",
    meta: klantprofieltOgbNAJATtMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/klantprofiel.vue")
  },
  {
    name: "leveringen-overzicht",
    path: "/leveringen-overzicht",
    meta: leveringen_45overzichtosZUgmsfQDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/leveringen-overzicht.vue")
  },
  {
    name: "nazendingen",
    path: "/nazendingen",
    meta: nazendingen4DxNFcTK6EMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nazendingen.vue")
  },
  {
    name: "parallel-bestellen",
    path: "/parallel-bestellen",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/parallel-bestellen.vue")
  },
  {
    name: "printen",
    path: "/printen",
    meta: printenws5zocMxc7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/printen.vue")
  },
  {
    name: "receptverwerking",
    path: "/receptverwerking",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/receptverwerking.vue")
  }
]