import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import activate_45page_45global from "/opt/atlassian/pipelines/agent/build/middleware/activatePage.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  activate_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/atlassian/pipelines/agent/build/middleware/auth.ts"),
  "un-authenticated": () => import("/opt/atlassian/pipelines/agent/build/middleware/unAuthenticated.ts")
}