import { query, where } from 'firebase/firestore'
import { Deal } from '../models/Deal'
import { ArrayService } from './serviceLive'

export class DealService extends ArrayService<Deal> {
  constructor() {
    super('Deals')
  }

  public listenForId(userId: string): void {
    const listenQuery = query(this.collection, where('UserId', '==', userId))
    this.listen(listenQuery)
  }
}
