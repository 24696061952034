import { useToast } from 'vue-toastification'
import { format, differenceInYears, isSameDay, addDays } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { nl } from 'date-fns/locale'

export default function useHelpers() {
  const toast = useToast()
  const { apiPost } = useApi()
  const { appUserActive } = storeToRefs(useSearchParcel())
  const { t } = useI18n()

  const beginOfDay = new Date().setHours(0, 0, 0, 0)
  const endOfDay = new Date().setHours(23, 59, 59, 0)

  function formatDate(formatString: string, date = new Date()) {
    return format(date, formatString, { locale: nl })
  }

  function dateDayMonthYear(date = new Date()) {
    return date.toLocaleString('nl-NL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  }

  function getAge(date: Date): number {
    return differenceInYears(new Date(), date)
  }

  function formatTimestamp(timestamp: Timestamp | undefined) {
    if (!timestamp || !timestamp.toDate) return null
    return dateDayMonthYear(timestamp.toDate())
  }

  function getDateTime(timestamp: Timestamp) {
    if (!timestamp || !timestamp.toDate) return null
    const date = timestamp.toDate()
    return (
      dateDayMonthYear(date) +
      ' ' +
      date.getHours().toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0')
    )
  }

  function formatTimestampMonthDayYear(timestamp: Timestamp | undefined) {
    if (!timestamp) return null

    if (!timestamp.toDate) {
      return new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      )
    }

    return timestamp.toDate()
  }

  function stripNumberFromString(input: any): number {
    if (typeof input !== 'number') {
      const numberFromStringRegex = /[+-]?\d+(.\d+)?/g
      input = parseInt(input.trim().match(numberFromStringRegex, '')[0])
    }
    return input
  }

  const timestampBeginOfDay = (date: Date = new Date()) =>
    Timestamp.fromDate(new Date(date.setHours(0, 0, 0, 0)))
  const timestampEndOfDay = (date: Date = new Date()) =>
    Timestamp.fromDate(new Date(date.setHours(23, 59, 59, 0)))

  async function sendEventNotificare({
    eventName,
    eventData,
    guid = appUserActive.value!.id
  }: {
    eventName: string
    eventData: {
      [key: string]: string
    }
    guid?: string
  }) {
    // Send custom event to notificare proxy
    try {
      await apiPost('notificare/call-api', {
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          guid,
          eventName,
          eventData
        }
      })

      toast.success(t('helpers.notificareSend'))
    } catch (error) {
      toast.error(t('helpers.somethingWentWrong'))
    }
  }

  function focusAndSelectInput(elementId: string) {
    // Focus on search input and select text
    const inputQuery = document.getElementById(elementId) as HTMLInputElement
    if (inputQuery) {
      inputQuery.focus()
      inputQuery.select()
    }
  }

  // Should alsways be unique
  function createExternalReference() {
    // Using AMP suggested format:
    // MED + timestamp in miliseconds (last 12 chars) + 3 random letters
    const timestamp = new Date().getTime()
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let letters = ''
    for (let i = 0; i < 3; i++) {
      letters += alphabet[Math.floor(Math.random() * alphabet.length)]
    }

    return `MED${timestamp.toString().slice(-12)}${letters}`
  }

  function truncateString(input: string, maxLength: number) {
    if (input.length <= maxLength) {
      return input
    }

    const truncatedString = input.substring(0, maxLength - 3)

    return truncatedString + '...'
  }

  function timestampToWord(timestamp: Timestamp | null): string {
    const yesterday = addDays(new Date(), -1)
    const today = new Date()
    const tomorrow = addDays(new Date(), 1)

    if (timestamp === null) {
      return '-'
    }

    const date = timestamp.toDate()

    if (isSameDay(date, yesterday)) {
      return t('date.yesterday')
    } else if (isSameDay(date, today)) {
      return t('date.today')
    } else if (isSameDay(date, tomorrow)) {
      return t('date.tomorrow')
    } else {
      return formatTimestamp(timestamp) ?? '-'
    }
  }

  return {
    beginOfDay,
    createExternalReference,
    dateDayMonthYear,
    endOfDay,
    focusAndSelectInput,
    formatDate,
    formatTimestamp,
    formatTimestampMonthDayYear,
    getAge,
    getDateTime,
    sendEventNotificare,
    stripNumberFromString,
    timestampBeginOfDay,
    timestampEndOfDay,
    timestampToWord,
    toast,
    truncateString
  }
}
