export interface PrescriptionLineContent {
  PrescriptionDate: string
  Prescriber: Prescriber
  Patient: Patient
  Medication: Medication
}
export interface Prescriber {
  PrescriberCode: string
  PrescriberType: string
  Name: string
  Address: Address
}
export interface Patient {
  Sex: string
  DateOfBirth: string
  Initials?: string
  Firstname?: string
  Lastname: string
  Address: Address
  InsurerCode: string
  InsurancePolicyNumber: string
  Bsn: string
}
export interface Address {
  Street: string
  HouseNumber: string
  City: string
  PostalCode: string
}
export interface Medication {
  AmountDelivered: number
  Name: string
  Code: string
  CodeType: CodeType
  Stopped: boolean
  WciaCode: string
  WciaReadable: string
  PrescriberNote: string
  StartDate: string
  EndDate: string
  AuthorisationDate: string
}

export enum CodeType {
  KNMP = 'KNMP',
  KNMPROD = 'KNMPROD',
  GPK = 'GPK',
  HPK = 'HPK',
  PRK = 'PRK'
}
