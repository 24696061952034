import AutoComplete from 'primevue/autocomplete'
import Button from 'primevue/button'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import MultiSelect from 'primevue/multiselect'
import OverlayPanel from 'primevue/overlaypanel'
import PrimeVue from 'primevue/config'
import Ripple from 'primevue/ripple'
import Row from 'primevue/row'
import Sidebar from 'primevue/sidebar'
import Stepper from 'primevue/stepper'
import StepperPanel from 'primevue/stepperpanel'
import Tooltip from 'primevue/tooltip'
import Tree from 'primevue/tree'
import AccordionTab from 'primevue/accordiontab'
import Accordion from 'primevue/accordion'
import Timeline from 'primevue/timeline'

export default defineNuxtPlugin((nuxtApp) => {
  const { t } = nuxtApp.$i18n

  nuxtApp.vueApp.use(PrimeVue, {
    locale: {
      selectionMessage: `{0} ${t('general.selected')}` // Muli-select
    },
    ripple: true,
    pt: {
      dialog: {
        root: 'rounded-2xl elevation-3',
        header: 'rounded-t-2xl',
        content: 'rounded-b-2xl',
        footer:
          'p-8 rounded-b-2xl -z-1 relative -top-[25px] pt-[calc(1rem+25px)]'
      },
      accordion: {
        accordionTab: {
          headerAction:
            'flex flex-row-reverse justify-end gap-2 font-baloo2 text-aubergine-dark-500'
        }
      },
      tooltip: {
        root: '!rounded-2xl !elevation-3'
      },
      dataTable: {
        headerRow: {
          class: 'bg-white'
        },
        column: {
          headerCell: {
            class:
              'bg-softlight-700 px-3 py-5 first:!rounded-l-2xl last:!rounded-r-2xl'
          },
          headerTitle: {
            class:
              'font-baloo2 text-aubergine-dark-500 text-sm whitespace-nowrap'
          },
          sortIcon: {
            class: 'text-zinc-300'
          },
          bodyCell: {
            class:
              'px-3 py-0 h-16 font-body text-aubergine-dark-500  first:!rounded-l-2xl last:!rounded-r-2xl'
          }
        },
        emptyMessageCell: {
          class: 'text-aubergine-dark-500 text-center rounded-2xl'
        },
        bodyRow: {
          class: 'border-b border-gray-200'
        }
      }
    }
  })

  nuxtApp.vueApp.component('Accordion', Accordion)
  nuxtApp.vueApp.component('AccordionTab', AccordionTab)
  nuxtApp.vueApp.component('AutoComplete', AutoComplete)
  nuxtApp.vueApp.component('Button', Button)
  nuxtApp.vueApp.component('Column', Column)
  nuxtApp.vueApp.component('ColumnGroup', ColumnGroup)
  nuxtApp.vueApp.component('DataTable', DataTable)
  nuxtApp.vueApp.component('Dialog', Dialog)
  nuxtApp.vueApp.component('Divider', Divider)
  nuxtApp.vueApp.component('Dropdown', Dropdown)
  nuxtApp.vueApp.component('Menu', Menu)
  nuxtApp.vueApp.component('Menubar', Menubar)
  nuxtApp.vueApp.component('MultiSelect', MultiSelect)
  nuxtApp.vueApp.component('OverlayPanel', OverlayPanel)
  nuxtApp.vueApp.component('Row', Row)
  nuxtApp.vueApp.component('Sidebar', Sidebar)
  nuxtApp.vueApp.component('Stepper', Stepper)
  nuxtApp.vueApp.component('StepperPanel', StepperPanel)
  nuxtApp.vueApp.component('Timeline', Timeline)
  nuxtApp.vueApp.component('Tree', Tree)
  nuxtApp.vueApp.directive('ripple', Ripple)
  nuxtApp.vueApp.directive('tooltip', Tooltip)
})
