import { orderBy, query, Timestamp, where } from 'firebase/firestore'
import { MapService } from './serviceLive'
import { PrescriptionLine } from '~/models/PrescriptionLine'

export class PrescriptionLineService extends MapService<PrescriptionLine> {
  constructor() {
    super('PrescriptionLine')
  }

  public listenForAll(referenceDate: Date = new Date()): void {
    const oneWeekAgo = this.oneWeekAgo(referenceDate)

    const listenQuery = query(
      this.collection,
      where('Created', '>=', oneWeekAgo),
      orderBy('Created', 'desc')
    )

    this.listen(listenQuery)
  }

  public listenForId(userId: string, referenceDate: Date = new Date()) {
    const oneWeekAgo = this.oneWeekAgo(referenceDate)

    const listenQuery = query(
      this.collection,
      where('Created', '>=', oneWeekAgo),
      where('UserId', '==', userId),
      orderBy('Created', 'desc')
    )

    this.listen(listenQuery)
  }

  private oneWeekAgo(referenceDate: Date) {
    return Timestamp.fromDate(
      new Date(referenceDate.valueOf() - 7 * 24 * 60 * 60 * 1000)
    )
  }
}
